import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReq } from "@utils/ApiHandler";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import { imageHandler } from "@utils/formatter";
import ENV from "@utils/env";
const UserProfile = () => {
  const { userId } = useParams();
  const [data, setData] = useState(null);

  const getUserDetails = () => {
    getReq(`/user/get-profile-details/${userId}`)
      .then((res) => {
        if (res.status) {
          setData(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (userId) getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  if (data)
    return (
      <main className="pt-20 text-white">
        <section className="bg-primary-voilet-dark">
          <div className="container justify-center flex gap-10 items-start py-10 ">
            <div className="lg:flex items-center gap-72 xl:gap-80">
              <div className="flex flex-col items-center justify-center lg:max-w-[450px] w-full">
                <div className="w-36 h-36 tablet:w-52 tablet:h-52 flex-shrink-0 rounded-full object-cover border-4 border-cyan-400 ">
                  <img
                    className="w-full h-full rounded-full object-cover "
                    src={`${ENV.BASE_URL}/get-profile/${userId}/image.png`}
                    alt="profile"
                  />
                </div>
                <div className="mt-8">
                  <div className=" text-center ">
                    <div className="flex items-center mb-1">
                      {" "}
                      <h4 className="uppercase font-semibold text-25">
                        {data.first_name || ""} {data.last_name || ""}
                      </h4>
                      {data.status === 1 && (
                        <span className="self-start ml-1">
                          <HiOutlineBadgeCheck className="text-xl text-green-500" />{" "}
                        </span>
                      )}
                    </div>

                    <p className="text-zinc-100 text-base font-medium mb-1">
                      {data.userType || "USER"}
                    </p>

                    {data.permanent_address && (
                      <p className="text-zinc-400  font-medium text-sm">
                        {/* {data.permanent_address.address1 || ""}{" "}
                        {data.permanent_address.address2 || " "}{" "} */}
                        {data.permanent_address.city || " "}{" "}
                        {data.permanent_address.country || " "}{" "}
                        {/* {data.permanent_address.zip || " "}{" "} */}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <div className="max-w-4xl">
                  <div className="flex sm:gap-2 md:gap-20 justify-center">
                    <div className="text-center min-w-[110px] md:min-w-[150px]">
                      <h3>
                        {kFormatter(
                          Math.floor(Math.random() * (1000 - 50 + 1) + 50)
                        )}
                      </h3>
                      <h6>Posts</h6>
                    </div>
                    <div className="text-center min-w-[110px] md:min-w-[150px]">
                      <h3>{kFormatter(data.follower_count)}</h3>
                      <h6>Follower</h6>
                    </div>
                    <div className="text-center min-w-[110px] md:min-w-[150px]">
                      <h3>
                        {kFormatter(
                          Math.floor(Math.random() * (1000 - 50 + 1) + 50)
                        )}
                      </h3>

                      <h6>Followings</h6>
                    </div>
                  </div>
                  <div className="flex gap-2 md:gap-4 justify-center flex-wrap mt-10">
                    {data.category.map((item, index) => (
                      <span className="text-lg md:text-xl text-zinc-400 font-inter">
                        # {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-12 border-t-zinc-600 border-t my-6">
            <p className="text-zinc-400 font-inter  max-w-7xl mx-auto">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
              ullam exercitationem corrupti sequi nesciunt dolores aliquam
              maiores ut tenetur? Repellat cumque voluptatum, quas accusantium
              laboriosam impedit in error vero incidunt doloribus reiciendis
              tempore vel necessitatibus nulla aut beatae suscipit neque omnis
              id! Reiciendis eius atque aperiam maxime accusamus quibusdam
              aspernatur magnam nesciunt ad consequuntur autem nam, nisi quae
              voluptates dignissimos aliquid tempore facilis incidunt minima
              veritatis illo quaerat quis. Facilis tempore accusantium, harum
              ducimus reprehenderit aliquid veritatis pariatur quia blanditiis
              iusto debitis eligendi nisi enim atque officiis nemo officia. Sint
              fugiat repudiandae, iusto perspiciatis assumenda odit rerum veniam
              dolorum neque.
            </p>
          </div>
        </section>
        <section className="bg-primary-voilet-dark py-10"></section>
      </main>
    );
  return <p>..Loading</p>;
};

export default UserProfile;
