import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getApiReq, getReq, postApiReq } from '@utils/ApiHandler';
import { Box, Center } from '@chakra-ui/react';
import { imageHandler } from '@utils/formatter';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
const CampaignInvitation = () => {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [data, setData] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [showMsg, setShowMsg] = useState('');
  const { user } = useSelector((state) => state.userDetails);
  const history = useHistory();

  const getCampaignDetails = () => {
    setShowButton(false);
    getReq(`/user/get-campaign-details/${campaignId}`).then((res) => {
      if (res.status) {
        setData(res.data);
      } else {
      }
    });
  };

  useEffect(() => {
    if (campaignId) getCampaignDetails();
  }, [campaignId]);

  const checkCampaignInvite = () => {
    getApiReq(`/user/check-invitation/${campaignId}`).then((res) => {
      if (res.status) {
        if (res.data.status === 'PENDING') setShowButton(true);
        else if (res.data.status === 'ACCEPTED')
          setShowMsg('Invitation Accepted');
        if (res.data.status === 'REJECTED') setShowMsg('Invitation REJECTED');
      } else {
        setShowButton(false);
      }
    });
  };
  const updateInvitation = (status) => {
    postApiReq(`/user/update-invitation`, { status, campaignId }).then(
      (res) => {
        if (res.status) {
          dispatch(
            showToast({
              type: 'success',
              message: `Campaign status Updated ( ${status} ) successfully`,
            })
          );
          if (status === 'ACCEPTED') history.push(`/campaign/${campaignId}`);
          else history.push(`/profile/${user._id}`);
        } else {
          dispatch(
            showToast({
              type: 'error',
              message: res.error,
            })
          );
        }
      }
    );
  };

  useEffect(() => {
    if (user && campaignId) checkCampaignInvite();
  }, [user, campaignId]);

  console.log(moment('2023-06-22').unix());

  console.log(data);
  if (data)
    return (
      <main className='py-20 px-2 '>
        <Box
          className='bg-[#070814] max-w-[600px] pb-5 flex flex-col rounded-lg shadow-[0_0_8px_3px_rgba(0,0,0,0.2)]  shadow-slate-800'
          m='120px auto 60px auto'
        >
          <Center pos='relative'>
            <h6 className='bold text-white text-2xl md:pt-6 pt-4'>
              Campaign Invitation
            </h6>
          </Center>
          <Box className='underline' h='0.5px'></Box>
          <Box className='underline' h='0.5px'></Box>{' '}
          <Box p='20px' w='100%'>
            <div className='inputs text-white my-5 py-5 tablet:px-10'>
              <div className='flex justify-center'>
                <img
                  className='rounded-full w-20 h-20'
                  src={
                    imageHandler(data?.brand?.profile, 1) || '/images/av.jpg'
                  }
                  alt='profile'
                />
              </div>
              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px] flex-shrink-0'>
                  Brand Name :{' '}
                </h6>
                <div className='font-inter font-medium'>
                  {data.brand.brandName}
                </div>
              </div>
              <div className='relative'>
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  spaceBetween={16}
                  loop={false}
                  loopFillGroupWithBlank={true}
                  grabCursor={true}
                  navigation={{
                    nextEl: '.review-swiper-button-next',
                    prevEl: '.review-swiper-button-prev',
                  }}
                  modules={[Pagination, Navigation]}
                  className='w-full flex justify-center'
                >
                  {data?.asset.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        className='mx-auto w-24 h-24 rounded-full object-cover'
                        src={imageHandler(item.presentation, 12)}
                        alt='av'
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className='review-swiper-button-prev  ay-center left-0 bg-zinc-600 w-7 h-7 tablet:w-10 tablet:h-10 rounded-full flex justify-center items-center cursor-pointer z-50  '>
                  <BiChevronLeft className='tablet:text-3xl text-lg text-white ' />
                </div>
                <div className='review-swiper-button-next  ay-center right-0 bg-zinc-600 w-7 h-7 tablet:w-10 tablet:h-10 rounded-full flex justify-center items-center cursor-pointer z-50  '>
                  <BiChevronRight className='tablet:text-3xl text-lg text-white ' />
                </div>
              </div>

              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px]  flex-shrink-0'>
                  Campaign Name :{' '}
                </h6>
                <div className='font-inter font-medium'>{data.name}</div>
              </div>
              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px]  flex-shrink-0'>
                  Start Date :{' '}
                </h6>
                <div className='font-inter font-medium'>
                  {moment.unix(data.from).format('DD-MM-YYYY')}
                </div>
              </div>
              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px]  flex-shrink-0'>
                  End Date :{' '}
                </h6>
                <div className='font-inter font-medium'>
                  {moment.unix(data.to).format('DD-MM-YYYY')}
                </div>
              </div>
              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px]  flex-shrink-0'>
                  Category :{' '}
                </h6>
                <div className='flex items-start gap-2 flex-wrap'>
                  {data.category.map((item) => (
                    <div className='font-inter font-medium'>{item}</div>
                  ))}
                </div>
              </div>
              <div className='flex items-start gap-2 py-5'>
                <h6 className='w-[149px] tablet:w-[180px]  flex-shrink-0'>
                  Description :{' '}
                </h6>

                <div className='font-inter font-medium'>{data.description}</div>
              </div>
            </div>
            {user ? (
              showButton ? (
                <div className='text-center justify-between text-white flex  py-5'>
                  <button
                    onClick={() => updateInvitation('REJECTED')}
                    type='button'
                    className='gradient_btn btn-primary'
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => updateInvitation('ACCEPTED')}
                    type='button'
                    className='gradient_btn btn-primary'
                  >
                    Accept
                  </button>
                </div>
              ) : (
                <div className='text-center  text-white flex justify-center py-5'>
                  <p>{showMsg}</p>
                </div>
              )
            ) : (
              <div className='text-center  text-white flex justify-center py-5'>
                <p>Please Login for Accept Invitation</p>
              </div>
            )}
          </Box>
        </Box>
        <section className='bg-primary-voilet-dark py-10'></section>
      </main>
    );
  return <h6>No Invitation Found</h6>;
};

export default CampaignInvitation;
