import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import "./assets/Sass/main.scss";
import "./index.css";
import React, { useEffect, lazy, Suspense } from "react";
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "@redux/action";
import Cookies from "js-cookie";
import Loader from "@components/Loader";
import "../node_modules/tw-elements/dist/js/index.min.js";
// import ComingSoon from "@pages/ComingSoon/ComingSoon";
// import CreateCampaign from "@pages/createCampaign";
import GifImplimentation from "@pages/GifImplimentation";
import UserProfile from "@pages/UserProfile";
import CampaignInvitation from "@pages/Campaign/CampaignInvitation";
import AllCampaign from "@pages/Campaign/AllCampaign";
import CampaignDetailPage from "@pages/Campaign/Campaign";

const NotFound = lazy(() => import("@pages/notFound"));
const Amadze = lazy(() => import("@pages/Amadze/Amadze"));
const AdvertNFT = lazy(() => import("@pages/AdvertNFT/AdvertNFT"));
const NftDetails = lazy(() => import("@pages/nftDetails"));
const ExploersNFT = lazy(() => import("@pages/ExploersNFT/ExploersNFT"));
const Profile = lazy(() => import("@pages/Profile/Profile"));
const Home = lazy(() => import("@pages/Home/Home"));
const AllNFT = lazy(() => import("@pages/AllNFT/AllNFT"));
const UserNFT = lazy(() => import("@pages/UserNFT/UserNFT"));
const Navbar = lazy(() => import("@components/Navbar/Navbar"));
const CustomizeFanCard = lazy(() => import("@pages/CustomizeFanCard"));
const ListItems = lazy(() => import("@pages/list-item/ListItem"));
const AssembleCard = lazy(() => import("@pages/AssembleCard"));
const EntertainmentPackDetails = lazy(() =>
  import("@pages/Packs/EntertainmentPackDetails")
);

const Footer = lazy(() => import("@components/Footer/Footer"));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Routes = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  window.web3 = new window.Web3(window.ethereum);

  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (Boolean(Cookies.get("user-data"))) dispatch(getUserDetails());
  }, []);

  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <div className="App">
        <Snackbar
          open={isVisible}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>

        {pathname !== "/coming-soon" ? <Navbar /> : null}
        <Switch>

          <Route path={"/"} exact component={Home} />

          <Route path={"/update-profile"} exact component={Profile} />
          <Route path={"/all-nft"} exact component={AllNFT} />
          <Route path={"/nft/:nftType"} exact component={UserNFT} />

          <Route path={"/explore-nft"} exact component={ExploersNFT} />
          <Route
            path={"/customize-fancard/:tokenId"}
            exact
            component={CustomizeFanCard}
          />
          <Route path={"/assemble-card"} exact component={AssembleCard} />
          <Route
            path={"/entertainment-details/:id"}
            exact
            component={EntertainmentPackDetails}
          />
          <Route path={"/amadz"} exact component={Amadze} />
          <Route path={"/amadz-marketplace"} exact component={AdvertNFT} />

          <Route path={"/assets/:artId/sell"} exact component={ListItems} />
          <Route
            path={"/nft-details/:landingHash/:storeId"}
            exact
            component={NftDetails}
          />
          <Route
            path={"/campaign/:campaignId"}
            exact
            component={CampaignDetailPage}
          />
          <Route
            path={"/invitation/:campaignId"}
            exact
            component={CampaignInvitation}
          />
          <Route path={"/campaigns"} exact component={AllCampaign} />

          {/* <Route
            path={"/invitation/:campaignId"}
            exact
            component={CampaignInvitation}
          /> */}
          <Route path={"/profile/:userId"} exact component={UserProfile} />
          <Route path={"/*"} exact component={NotFound} />
        </Switch>

        {pathname !== "/coming-soon" ? <Footer /> : null}
      </div>
    </Suspense>
  );
};

const App = () => {
  return <Routes />;
};
export default App;
