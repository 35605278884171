import Loader from "@components/Loader";
import { showToast } from "@redux/action";
import { getApiReq, postApiReq } from "@utils/ApiHandler";
import { imageHandler } from "@utils/formatter";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AllCampaign = () => {
  const { user } = useSelector((state) => state.userDetails);
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserCampaign = () => {
    setIsLoading(true);
    getApiReq(`/user/get-influencer-campaign?status=${tab}`)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const updateInvitation = (campaignId) => {
    postApiReq(`/user/update-invitation`, {
      status: "ACCEPTED",
      campaignId,
    }).then((res) => {
      if (res.status) {
        getUserCampaign();
        dispatch(
          showToast({
            type: "success",
            message: `Campaign status Updated successfully`,
          })
        );
      } else {
        dispatch(
          showToast({
            type: "error",
            message: res.error,
          })
        );
      }
    });
  };

  useEffect(() => {
    if (user) getUserCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, tab]);
  if (user)
    return (
      <main className="pt-20">
        <Loader isLoading={isLoading} />
        <section className="bg-primary-voilet-dark">
          <div className="container text-white grid grid-cols-6 gap-5 tablet:gap-10 items-start pt-10 pb-3">
            <div className="col-span-6 lg:col-span-2 flex flex-col items-center justify-center">
              <div className="w-36 h-36 tablet:w-52 tablet:h-52 flex-shrink-0 rounded-full object-cover border-4 border-cyan-400 ">
                <img
                  className="w-full h-full rounded-full object-cover "
                  src={imageHandler(user.profile, 1) || "/images/body.png"}
                  alt="profile"
                />
              </div>

              <div className="mt-4">
                <h4 className="heading-4">
                  {user.first_name} {user.last_name}{" "}
                </h4>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4 text-center lg:text-start lg:pt-16">
              <h2 className="lg:pt-20 font-bold lg:text-start text-[22px] tablet:text-[26px] md:text-[45px] uppercase">
                Advertiser Campaigns
              </h2>
              <h6 className="pt-3 xl:pt-32 font-bold tablet:pl-4 text-[18px] tablet:text-[30px] md:text-[35px]">
                brands / Campaigns to endorse
              </h6>
            </div>
          </div>
          <div className="container tablet:flex my-6 gap-10 w-full text-white">
            <button
              onClick={() => setTab(1)}
              className={`border ${
                tab === 1 && "gradient_btn"
              }  btn  center py-3 rounded-md  flex-1 `}
            >
              Accepted Campaigns
            </button>
            <button
              onClick={() => setTab(2)}
              className={` border ${
                tab === 2 && "gradient_btn"
              }  btn center py-3 rounded-md flex-1  `}
            >
              Pending / Rejected Campaigns
            </button>
          </div>
        </section>
        {data.length > 0 ? (
          <section className="">
            <div className="container justify-items-center grid grid-cols-1 tablet:grid-cols-2 md:grid-cols-4 gap-8 text-black py-8 ">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center gap-6"
                >
                  <div className="w-36 h-36 tablet:w-52 tablet:h-52 lg:w-60 lg:h-60 rounded-full ">
                    <img
                      className="w-full h-full rounded-full object-cover"
                      src={
                        imageHandler(item.asset?.[0]?.presentation) ||
                        "/images/av.jpg"
                      }
                      alt="t-shirt"
                    />
                  </div>
                  <p className="text-white text-center overflow-hidden pt-2">
                    {" "}
                    {item.asset?.[0]?.text || " "}
                  </p>
                  {tab === 2 ? (
                    <h6
                      onClick={() => updateInvitation(item._id)}
                      className="underline cursor-pointer text-white"
                    >
                      Accept invitation
                    </h6>
                  ) : (
                    <h6
                      onClick={() => history.push(`/campaign/${item._id}`)}
                      className="underline cursor-pointer text-white"
                    >
                      View Details
                    </h6>
                  )}
                </div>
              ))}
            </div>
          </section>
        ) : (
          <h6 className="text-center text-white"> No campaign found </h6>
        )}

        <section className="bg-primary-voilet-dark py-10"></section>
      </main>
    );
  return <p>Loading</p>;
};
export default AllCampaign;
