import React, { useEffect, useState } from "react";
import { getApiReq, getImageUrl, getReq, postApiReq } from "@utils/ApiHandler";
import Loader from "@components/Loader";
import { useParams } from "react-router-dom";
import { Box, ImageList } from "@material-ui/core";
import NftCard from "./Card";
import { createGIF } from "gifshot";
import * as htmlToImage from "html-to-image";
import { imageHandler } from "@utils/formatter";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, setWalletId, showToast } from "@redux/action";
import ENV, { contracts } from "@utils/env";
import AssetContractShared from "@contracts/AssetContractShared.json";
import { cutString, shortCryptoAddress } from "@utils/basicReq";
import Cookies from "js-cookie";

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let image = new File([u8arr], filename, { type: mime });

  return image;
};

const chain = "POLYGON";

const Campaign = () => {
  const { campaignId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [step, setStep] = useState(0);
  const [gifImage, setGifImage] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userDetails);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [addConnectedWallet, setAddConnectedWallet] = useState(false);
  const [cryptoAccount, setCryptoAccount] = useState(false);
  const { ethereum } = window;

  useEffect(() => {
    const checkConnection = async () => {
      // Check if browser is running Metamask
      if (step === 1 && user) {
        if (Boolean(ethereum && ethereum.isMetaMask)) {
          try {
            // Will open the MetaMask UI
            await ethereum
              .request({ method: "eth_accounts" })
              .then(async (res) => {
                if (res.length === 0) {
                  setShowConnectWallet(true);
                } else {
                  //Check if requested wallet is connect or not
                  console.log("account", res);
                  console.log("user", user);
                  if (user?.address) {
                  } else {
                    setShowConnectWallet(false);
                    setCryptoAccount(res[0]);
                    if (
                      // eslint-disable-next-line no-restricted-globals
                      await confirm(
                        `Do you want to make your address ${res[0]} official for this account`
                      )
                    ) {
                      await addConnectedWalletWithUser(res[0]);
                    } else {
                      setAddConnectedWallet(true);
                    }
                  }
                }
              });
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        } else {
          dispatch(
            showToast({ type: "error", message: "Metamask Not Install" })
          );
          setIsLoading(false);
        }
      }
    };
    checkConnection();
  }, [step, user]);

  const addConnectedWalletWithUser = async (address) => {
    getApiReq(`/user/authenticate/${address}`).then((authResponse) => {
      if (authResponse.status) {
        ethereum
          .request({
            method: "personal_sign",
            params: [`${authResponse.data.consent}`, address],
          })
          .then((sigRes) => {
            postApiReq(`/user/authenticate`, {
              address: address,
              signature: sigRes,
            })
              .then((authTokenRes) => {
                if (authTokenRes.status) {
                  Cookies.set("user-data", authTokenRes.data.token, {
                    expires: 1,
                  });
                  dispatch(
                    showToast({
                      type: "success",
                      message: "Metamask wallet connect",
                    })
                  );
                  setShowConnectWallet(false);
                  getUserDetails();
                  dispatch(setWalletId(address));
                  setIsLoading(false);
                } else {
                  dispatch(
                    showToast({
                      type: "error",
                      message: authTokenRes.error,
                    })
                  );
                  setIsLoading(false);
                }
              })
              .catch((e) => {
                setIsLoading(false);
                dispatch(
                  showToast({
                    type: "error",
                    message: e?.authResponse?.data,
                  })
                );
              });
          })
          .catch((e) => {
            setIsLoading(false);
            dispatch(showToast({ type: "error", message: e?.message }));
          });
      } else {
        setIsLoading(false);
        dispatch(showToast({ type: "error", message: authResponse.error }));
        setAddConnectedWallet(true);
      }
    });
  };

  const getCampaignDetails = () => {
    setIsLoading(true);
    getReq(`/user/get-campaign-details/${campaignId}`)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (campaignId) getCampaignDetails();
    setShowConnectWallet(true);
  }, [campaignId]);

  const handleSelectItem = async (data) => {
    let temp = selectedImage.slice(0);

    let itemExist = temp.find((item) => {
      if (item.presentation === data.presentation) return true;
      return false;
    });

    if (itemExist) {
      temp = temp.filter((item) => item.presentation !== data.presentation);
    } else if (temp?.length < 6) {
      temp.push(data);
    }

    setSelectedImage(temp);
  };

  useEffect(() => {
    setGifImage("");
  }, [step]);

  const handleGif = (combineImage) => {
    const options = {
      images: combineImage,
      gifWidth: 350,
      gifHeight: 500,
      numWorkers: 5,
      frameDuration: 15,
      sampleInterval: 1,
    };

    createGIF(options, async (obj) => {
      if (!obj.error) {
        const link = document.createElement("a");

        const meargeImageFile = await dataURLtoFile(
          obj.image,
          "combineImage.gif"
        );
        let data = new FormData();
        data.append("asset", meargeImageFile);
        let image = await getImageUrl(data);

        createProfileCampaign([image], "GIF");
        link.download = "amadz.gif";
        link.href = obj.image;
        link.click();
        setGifImage(obj.image);
        setIsLoading(false);
      }
    });
  };

  const handleGenrateGif = async (isDynamicNft) => {
    if (user) {
      setIsLoading(true);

      if (isDynamicNft) {
        const accounts = await enableEthereum();

        if (
          window.web3.utils.toChecksumAddress(accounts) !==
          window.web3.utils.toChecksumAddress(user.address)
        ) {
          dispatch(
            showToast({
              type: "error",
              message: "please connect correct wallet",
            })
          );
          setIsLoading(false);
          return;
        }
      }
      const combineImage = [];

      let userImage;
      const allImages = [];

      let node = document.getElementById(`non-wearable`);
      const imagePromise = await new Promise((resolve, reject) => {
        htmlToImage
          .toPng(node)
          .then(async (dataUrl) => {
            try {
              const meargeImageFile = await dataURLtoFile(
                dataUrl,
                "combineImage.png"
              );
              let data = new FormData();
              data.append("asset", meargeImageFile);
              let image = await getImageUrl(data);
              userImage = image;
              resolve();
            } catch (e) {
              console.error(e);
              reject();
              setIsLoading(false);
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      });

      await Promise.all(
        selectedImage.map((item, index) => {
          let node = document.getElementById(`item-${index}`);
          return new Promise((resolve, reject) => {
            htmlToImage
              .toPng(node)
              .then(async (dataUrl) => {
                try {
                  const meargeImageFile = await dataURLtoFile(
                    dataUrl,
                    "combineImage.png"
                  );
                  let data = new FormData();
                  data.append("asset", meargeImageFile);
                  let image = await getImageUrl(data);
                  combineImage.push(image);
                  resolve();
                } catch (e) {
                  console.error(e);
                  reject();
                  setIsLoading(false);
                }
              })
              .catch(function (error) {
                console.error("oops, something went wrong!", error);
              });
          });
        })
      );

      const allPromise = await Promise.all(
        combineImage.map(
          (item) =>
            new Promise((resolve, reject) => {
              createGIF(
                {
                  images: [userImage, item],
                  gifWidth: 350,
                  gifHeight: 500,
                  numWorkers: 5,
                  frameDuration: 15,
                  sampleInterval: 1,
                },
                async (obj) => {
                  if (!obj.error) {
                    try {
                      const meargeImageFile = await dataURLtoFile(
                        obj.image,
                        "combineImage.gif"
                      );
                      let data = new FormData();
                      data.append("asset", meargeImageFile);
                      let image = await getImageUrl(data);

                      allImages.push(image);
                      if (!isDynamicNft) {
                        const link = document.createElement("a");
                        link.download = "amadz.gif";
                        link.href = obj.image;
                        link.click();
                        setIsLoading(false);
                      }
                      resolve();
                    } catch (e) {
                      console.error(e);
                      setIsLoading(false);
                      reject();
                    }
                  }
                }
              );
            })
        )
      );

      if (isDynamicNft) {
        handleDynamicNFT(allImages);
        createProfileCampaign(allImages, "DYNAMIC");
      } else {
        createProfileCampaign(allImages, "GIF");
      }
    } else {
      dispatch(
        showToast({ type: "error", message: "Please connect your wallet" })
      );
    }
  };

  const createProfileCampaign = (images, type) => {
    let campaignData = {
      campaignId: campaignId,
      userId: user._id,
      campaignType: type,
      from: data.from,
      to: data.to,
      images: images,
    };
    postApiReq("/user/create-profile-campaign", campaignData)
      .then((res) => {
        if (!res.status) {
          dispatch(
            showToast({
              type: "error",
              message: res.error,
            })
          );
        }
      })
      .catch((e) => console.log(e));
  };

  const connectWallet = () => {
    const account = enableEthereum();
    if (account.length > 0) {
      setCryptoAccount(account[0]);
    }
  };

  const enableEthereum = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    window.web3 = new window.Web3(window.ethereum);
    return accounts[0];
  };

  const handleDynamicNFT = async (combineImage) => {
    setIsLoading(true);
    const tempImages = [];

    let metaUrl;
    for (let i = 0; i < combineImage.length; i++) {
      tempImages.push(combineImage[i].split("/").pop());
    }

    const sessionId =
      new Date().getTime() + (Math.random() + 1).toString(36).substring(7);

    const dynamicJson = {
      userId: user._id,
      images: tempImages,
      sessionId: sessionId,
    };

    const nftJson = {
      name: "Dynamic-NFT",
      description:
        "a Non-Fungible Token (NFT) with encoded smart contract logic that enables it to automatically change its metadata based on external conditions.",
      image: `${ENV.BASE_URL}/get-dynamics/${sessionId}/image.png`,
      attributes: [],
    };

    await postApiReq("/create-dynamics-metadata", dynamicJson)
      .then((res) => {
        if (!res.status) {
          dispatch(
            showToast({
              type: "error",
              message: res.error,
            })
          );
        }
      })
      .catch((e) => console.log(e));

    await postApiReq("/metadata", nftJson)
      .then((res) => {
        if (res.status) {
          metaUrl = `${ENV.BASE_URL}/metadata/${res.data}`;
        }
      })
      .catch((e) => console.log(e));

    const accounts = await enableEthereum();

    if (
      window.web3.utils.toChecksumAddress(accounts) !==
      window.web3.utils.toChecksumAddress(user.address)
    ) {
      dispatch(
        showToast({
          type: "error",
          message: "please connect correct wallet",
        })
      );
      setIsLoading(false);
      return;
    }

    window.web3.eth.handleRevert = true;
    const ContractInstance = new window.web3.eth.Contract(
      AssetContractShared,
      contracts[chain].AssetContractShared
    );

    const response = await ContractInstance.methods
      .mint(1, window.web3.utils.utf8ToHex(metaUrl))
      .send({ from: accounts })
      .catch((err) => err);

    if (typeof response === "object" && response?.code) {
      // error
      const message = response?.message;
      dispatch(showToast({ type: "error", message: message }));
      setIsLoading(false);
    } else {
      dispatch(
        showToast({
          type: "success",
          message: "NFT created successfully",
        })
      );
      setStep(0);
      setSelectedImage([]);
      setIsLoading(false);
    }
  };

  const handleNonwearableGif = async (isDynamicNft) => {
    if (user) {
      setIsLoading(true);

      if (isDynamicNft) {
        const accounts = await enableEthereum();

        if (
          window.web3.utils.toChecksumAddress(accounts) !==
          window.web3.utils.toChecksumAddress(user.address)
        ) {
          dispatch(
            showToast({
              type: "error",
              message: "please connect correct wallet",
            })
          );
          setIsLoading(false);
          return;
        }
      }

      let userImage;

      const allImages = [];

      let node = document.getElementById(`non-wearable`);
      const imagePromise = await new Promise((resolve, reject) => {
        htmlToImage
          .toPng(node)
          .then(async (dataUrl) => {
            try {
              const meargeImageFile = await dataURLtoFile(
                dataUrl,
                "combineImage.png"
              );
              let data = new FormData();
              data.append("asset", meargeImageFile);
              let image = await getImageUrl(data);
              userImage = image;
              resolve();
            } catch (e) {
              console.error(e);
              reject();
              setIsLoading(false);
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      });

      const combineImage = [];
      await Promise.all(
        selectedImage.map((item, index) => {
          let node = document.getElementById(`item-${index}`);
          return new Promise((resolve, reject) => {
            htmlToImage
              .toPng(node)
              .then(async (dataUrl) => {
                try {
                  const meargeImageFile = await dataURLtoFile(
                    dataUrl,
                    "combineImage.png"
                  );
                  let data = new FormData();
                  data.append("asset", meargeImageFile);
                  let image = await getImageUrl(data);
                  combineImage.push(image);
                  resolve();
                } catch (e) {
                  console.error(e);
                  reject();
                  setIsLoading(false);
                }
              })
              .catch(function (error) {
                console.error("oops, something went wrong!", error);
              });
          });
        })
      );

      const allPromise = await Promise.all(
        combineImage.map(
          (item) =>
            new Promise((resolve, reject) => {
              createGIF(
                {
                  images: [userImage, imageHandler(item, 12)],
                  gifWidth: 350,
                  gifHeight: 500,
                  numWorkers: 5,
                  frameDuration: 15,
                  sampleInterval: 1,
                },
                async (obj) => {
                  if (!obj.error) {
                    try {
                      const meargeImageFile = await dataURLtoFile(
                        obj.image,
                        "combineImage.gif"
                      );
                      let data = new FormData();
                      data.append("asset", meargeImageFile);
                      let image = await getImageUrl(data);

                      allImages.push(image);
                      if (!isDynamicNft) {
                        const link = document.createElement("a");
                        link.download = "amadz.gif";
                        link.href = obj.image;
                        link.click();
                        setIsLoading(false);
                      }
                      resolve();
                    } catch (e) {
                      console.error(e);
                      setIsLoading(false);
                      reject();
                    }
                  }
                }
              );
            })
        )
      );
      if (isDynamicNft) {
        createProfileCampaign(allImages, "DYNAMIC");
        handleDynamicNFT(allImages);
      } else {
        createProfileCampaign(allImages, "GIF");
      }
    } else {
      dispatch(
        showToast({ type: "error", message: "Please connect your wallet" })
      );
    }
  };

  console.log(user?.status === 0 && addConnectedWallet, "ram");

  if (data)
    return (
      <>
        <main className="pt-20">
          <Loader isLoading={isLoading} />
          <section className="text-white">
            <div className="container py-10 ">
              <div className="lg:flex gap-8 items-start">
                <div className="w-36 h-36 tablet:w-52 tablet:h-52 flex-shrink-0 mx-auto rounded-full object-cover border-4 border-cyan-400 ">
                  <img
                    className="w-full h-full rounded-full object-cover "
                    src={
                      imageHandler(data.brand.profile, 1) || "/images/av.jpg"
                    }
                    alt="profile"
                  />
                </div>
                <div className="lg:max-w-[446px] xl:max-w-[650px] mt-5 lg:mt-0 w-full">
                  <div className=" text-center teblet:text-left ">
                    <div className="flex items-center justify-center mb-1 ">
                      <h4 className="uppercase font-semibold text-2xl ">
                        {data.name || ""}
                      </h4>
                    </div>

                    <p className="text-zinc-300 text-base font-inter leading-7  mb-1">
                      {data.description || ""}
                    </p>
                  </div>
                </div>
                <div className="mt-10 lg:mt-0 tablet:flex flex-grow justify-around lg:justify-between gap-5">
                  <div className="flex flex-col justify-around gap-2">
                    <div className="font-inter">
                      <h6>
                        Start Date :{" "}
                        {moment.unix(data.from).format("DD-MM-YYYY")}
                      </h6>
                    </div>
                    <h6>Category :</h6>
                    <div className="flex gap-4  flex-wrap  mt-4 mb-4">
                      {data.category.map((item, index) => (
                        <span
                          key={index}
                          className="text-sm bg-blue-400 px-3 py-1 block rounded-full text-white font-inter"
                        >
                          # {item}
                        </span>
                      ))}
                    </div>
                    <h6>Influencer :</h6>
                    <div className="flex gap-4  flex-wrap  mt-4 mb-4">
                      {data.influencer_type.map((item, index) => (
                        <span
                          key={index}
                          className="text-sm bg-blue-400 px-3 py-1 block rounded-full text-white font-inter"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                    <h6>Platform :</h6>
                    <div className="flex gap-4  flex-wrap  mt-4 mb-4">
                      {data.platform.map((item, index) => (
                        <span
                          key={index}
                          className="text-sm bg-blue-400 px-3 py-1 block rounded-full text-white font-inter"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="font-inter">
                      <h6>
                        End Date : {moment.unix(data.to).format("DD-MM-YYYY")}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="border-t-zinc-600 border-t my-6">
            {step === 0 ? (
              <div className="container py-10 border-t-zinc-600">
                <Box sx={{ width: "100%", height: "auto" }}>
                  <ImageList
                    variant="masonry"
                    sx={{
                      columnCount: {
                        xs: "1 !important",
                        sm: "2 !important",
                        md: "3 !important",
                        lg: "4 !important",
                        xl: "5 !important",
                      },
                    }}
                    gap={10}
                  >
                    {data?.asset?.map((item, index) => (
                      <NftCard
                        data={item}
                        key={index}
                        index={index}
                        handleSelectItem={handleSelectItem}
                        selectedImage={selectedImage}
                      />
                    ))}
                  </ImageList>
                </Box>
                <div className={"mb-5"}>
                  <button
                    className={
                      selectedImage?.length > 1
                        ? "btn gradient_btn float-right mb-5 text-white"
                        : "btn gradient_btn disabled float-right mb-5 text-white"
                    }
                    onClick={() => {
                      selectedImage?.length > 1 && setStep(1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="container py-10 border-t-zinc-600">
                <div className="">
                  <div className="">
                    <div className="flex justify-center">
                      <div
                        id={`non-wearable`}
                        className="w-[350px] h-[500px] relative"
                      >
                        <img
                          src="/images/Tshirts-bg.png"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                          }}
                          alt={"img"}
                          crossOrigin="anonymous"
                        />

                        <img
                          src={`/images/body.png`}
                          style={{
                            zIndex: 2,
                            position: "absolute",
                          }}
                          alt={"img"}
                          crossOrigin="anonymous"
                        />
                      </div>
                    </div>
                    <div className="">
                      <div
                        className=" 
                      grid grid-cols-4 gap-10 mt-12"
                      >
                        {data.campaign_type === "wearable"
                          ? selectedImage?.map((item, index) => (
                              <div
                                id={`item-${index}`}
                                className="w-[350px] h-[500px] relative"
                              >
                                <img
                                  key={index}
                                  src={
                                    imageHandler(item?.implementation, 12) +
                                    "?not-from-cache-please"
                                  }
                                  style={{
                                    zIndex: index + 3,
                                    width: "100%",
                                    position: "absolute",
                                    pointerEvents: "none",
                                  }}
                                  alt={"img"}
                                  crossOrigin="anonymous"
                                />

                                <img
                                  src="/images/Tshirts-bg.png"
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                  alt={"img"}
                                  crossOrigin="anonymous"
                                />

                                <img
                                  src={`/images/body.png`}
                                  style={{
                                    zIndex: 2,
                                    position: "absolute",
                                  }}
                                  alt={"img"}
                                  crossOrigin="anonymous"
                                />
                                <h6
                                  className="text-white absolute text-center w-full left-0 bottom-4 px-2 py-4 z-[10]"
                                  style={{
                                    zIndex: index + 4,
                                  }}
                                >
                                  {item.text}
                                </h6>
                              </div>
                            ))
                          : selectedImage?.map((item, index) => (
                              <div
                                id={`item-${index}`}
                                className="w-full relative rounded-md overflow-hidden"
                              >
                                <img
                                  key={index}
                                  src={
                                    imageHandler(item?.presentation, 12) +
                                    "?not-from-cache-please"
                                  }
                                  className="w-full object-cover"
                                  alt={"img"}
                                  crossOrigin="anonymous"
                                />
                                <h6
                                  className="text-white absolute text-center w-full left-0 bottom-4 px-2 py-4 z-[10]"
                                  style={{
                                    zIndex: index + 4,
                                  }}
                                >
                                  {item.text}
                                </h6>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                  {data.campaign_type === "wearable" ? (
                    <div className="flex justify-between items-center pt-10">
                      <div className="block ">
                        <div className="block">
                          {user.address && (
                            <>
                              {" "}
                              <button
                                onClick={() => {
                                  user?.status === 1 && handleGenrateGif(true);
                                }}
                                className={
                                  user?.status === 1
                                    ? "btn gradient_btn float-right mb-5 text-white"
                                    : "btn gradient_btn disabled float-right mb-5 text-white"
                                }
                              >
                                {`Generate dynamic NFT with ${shortCryptoAddress(
                                  user.address
                                )}`}
                              </button>
                              {user?.status === 0 && (
                                <p className="text-red-500">
                                  Your profile is not approved by admin
                                </p>
                              )}
                            </>
                          )}
                          {!user.address && (
                            <>
                              {user?.status === 0 && showConnectWallet && (
                                <button
                                  onClick={() => connectWallet()}
                                  className={
                                    "btn gradient_btn float-right mb-5 text-white"
                                  }
                                >
                                  Connect Wallet with your account
                                </button>
                              )}
                              {user?.status === 0 && addConnectedWallet && (
                                <button
                                  onClick={() => {
                                    addConnectedWalletWithUser(cryptoAccount);
                                  }}
                                  className={
                                    "btn gradient_btn float-right mb-5 text-white"
                                  }
                                >
                                  {`Authorize (${shortCryptoAddress(
                                    cryptoAccount
                                  )})`}
                                </button>
                              )}

                              {/* {user?.status === 0 && (
                              <p className="text-red-500">
                                Your profile is not approved by admin
                              </p>
                            )} */}
                            </>
                          )}
                        </div>
                      </div>
                      {gifImage && <img src={gifImage} alt={"img"} />}
                      <button
                        onClick={() => handleGenrateGif(false)}
                        className={
                          "btn gradient_btn float-right mb-5 text-white"
                        }
                      >
                        Generate gif
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-between items-center pt-10">
                      <div className="block">
                        {user.address && (
                          <>
                            {" "}
                            <button
                              onClick={() => {
                                user?.status === 1 && handleGenrateGif(true);
                              }}
                              className={
                                user?.status === 1
                                  ? "btn gradient_btn float-right mb-5 text-white"
                                  : "btn gradient_btn disabled float-right mb-5 text-white"
                              }
                            >
                              {`Generate dynamic NFT with ${shortCryptoAddress(
                                user.address
                              )}`}
                            </button>
                            {user?.status === 0 && (
                              <p className="text-red-500">
                                Your profile is not approved by admin
                              </p>
                            )}
                          </>
                        )}
                        {!user.address && (
                          <>
                            {user?.status === 0 && showConnectWallet && (
                              <button
                                onClick={() => connectWallet()}
                                className={
                                  "btn gradient_btn float-right mb-5 text-white"
                                }
                              >
                                Connect Wallet with your account
                              </button>
                            )}
                            {user?.status === 0 && addConnectedWallet && (
                              <button
                                onClick={() => {
                                  addConnectedWalletWithUser(cryptoAccount);
                                }}
                                className={
                                  "btn gradient_btn float-right mb-5 text-white"
                                }
                              >
                                {`Authorize (${shortCryptoAddress(
                                  cryptoAccount
                                )})`}
                              </button>
                            )}

                            {/* {user?.status === 0 && (
                              <p className="text-red-500">
                                Your profile is not approved by admin
                              </p>
                            )} */}
                          </>
                        )}
                      </div>
                      {gifImage && <img src={gifImage} alt={"img"} />}
                      <button
                        onClick={() => handleNonwearableGif(false)}
                        className={
                          "btn gradient_btn float-right mb-5 text-white"
                        }
                      >
                        Generate gif
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>
        </main>
      </>
    );
  return <Loader isLoading={false} />;
};

export default Campaign;
