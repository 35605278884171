import React from "react";
import Loader from "@components/Loader";
import { imageHandler } from "@utils/formatter";
import { BiCheck } from "react-icons/bi";
import { ImageListItem } from "@material-ui/core";

const Card = ({ data, handleSelectItem, selectedImage, index }) => {
  if (data)
    return (
      <ImageListItem key={index} sx={{width:'100%'}}>
      <label
        htmlFor={`item-${index}`}
        className="w-full block relative cursor-pointer "
      >
        <input
          type="checkbox"
          name={""}
          id={`item-${index}`}
          onChange={() => handleSelectItem(data)}
          className="peer hidden"
          checked={Boolean(
            selectedImage.find((item) => {
              if (item.presentation === data.presentation) return true;
              return false;
            })
          )}
        />
        <span className="w-8 h-8 absolute top-2 right-2 rounded-full bg-black/40 hidden peer-checked:flex items-center justify-center">
          <BiCheck className="text-white text-2xl " />
        </span>
        <img
          src={imageHandler(data?.presentation, 12)}
          srcSet={imageHandler(data?.presentation, 12)}
          alt={imageHandler(data?.presentation, 12)}
          loading="lazy"
          className="rounded-xl rounded-b-none w-full"
        />
        <div className="py-4 rounded-xl rounded-t-none  px-4 bg-zinc-800 text-white">
          <p className="font-inter leading-[20px]">{data.text || " "}</p>
        </div>
      </label>
      </ImageListItem>     
    );
  return <Loader isLoading={true} />;
};

export default Card;
