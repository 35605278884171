import Cookies from "js-cookie";
import { zeroAddress } from "./env";

export const isUserLoggedIn = () => {
  return Boolean(Cookies.get("user-data"));
};

export const cutString = (string, length) => {
  if (typeof string != "string") string = JSON.stringify(string);
  if (string?.length < length) return string;

  return string?.substring(0, length) + "...";
};

export const getImageArray = (data) => {
  let tempArray = [...data];
  for (let i = 0; i < data.length; i++) {
    if (tempArray.length !== 6) tempArray.push(data[i]);
    else break;
  }
  if (tempArray.length === 6) return tempArray;
  return getImageArray(tempArray);
};

// export const imageFormate = ['jpg', 'jpeg', 'png']

export const renderCurrency = (chain, paymentToken) => {
  if (paymentToken === false) {
    if (chain === "ETHEREUM") return "ETH";
    else if (chain === "POLYGON") return "MATIC";
    return;
  } else if (chain === "ETHEREUM") {
    if (paymentToken === zeroAddress) {
      return "ETH";
    } else {
      return "WETH";
    }
  } else if (chain === "POLYGON") {
    if (paymentToken === zeroAddress) {
      return "MATIC";
    } else {
      return "WMATIC";
    }
  } else return;
};

export const audioFormats = ["mp3", "m4A"];

export const videoFormats = ["mp4", "OGG"];

export const imageFormate = ["png"];

export const supportedFormate = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "webP",
  "mp4",
  "OGG",
];

export function shortCryptoAddress(inputString) {
  if (inputString.length <= 6) {
    return inputString;
  }

  const leftPart = inputString.slice(0, 3);
  const rightPart = inputString.slice(-3);

  const middleDots = "...";

  return `${leftPart}${middleDots}${rightPart}`;
}
